var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Tab, TablePagination, Tabs, useTheme, } from '@mui/material';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import Messages from '../../../../../../shared/helpers/errorMessages';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import showNotification from '../../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import InitiateSettlements from './../InitiateSettlements';
import ClientSettlementsTableBody from './ClientSettlementsBody';
import { getComparator, stableSort } from '../../../../../../shared/helpers/tables';
import { ASSETS } from './assets';
function mapSettlements(settlements) {
    return settlements.map((item) => {
        var _a, _b, _c, _d;
        return {
            clientCode: item.client_code,
            settlementCode: item.settlement_code,
            currency: item.currency_code,
            status: item.state,
            failedReason: item.failed_reason || '',
            commands: item.commands,
            collapsed: false,
            dateFrom: ((_a = item.data) === null || _a === void 0 ? void 0 : _a.records) && item.data.records[0].type === 'date_from' ? (_b = item.data) === null || _b === void 0 ? void 0 : _b.records[0].value : undefined,
            dateTo: ((_c = item.data) === null || _c === void 0 ? void 0 : _c.records) && item.data.records[1].type === 'date_to' ? (_d = item.data) === null || _d === void 0 ? void 0 : _d.records[1].value : undefined,
            created_at: item.created_at,
            poolBalance: item.poolBalance,
        };
    });
}
const ClientSettlementsTable = (props) => {
    const [initiateDialog, setInitiateDialog] = useState(false);
    const [declineDialog, setDeclineDialog] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [settlements, setSettlements] = useState([]);
    const [statusFilter, setStatusFilter] = useState('created');
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({
        order: 'desc',
        orderBy: 'created_at',
        rowsPerPage: 10,
        page: 0,
    });
    const theme = useTheme();
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
        return yield axios
            .get('settlements/accounts', {
            baseURL: getEnvSettings().adminApiUrl,
            params: {
                states: statusFilter,
                client_code: getEnvSettings().etoroClientId,
                limit: statusFilter !== 'created' ? state.rowsPerPage : null,
                page: statusFilter !== 'created' ? state.page + 1 : null,
                sort_by: state.orderBy === 'created_at' ? state.orderBy : null,
                sort_direction: state.order,
            },
        })
            .then((response) => __awaiter(void 0, void 0, void 0, function* () {
            setTotalItems(+response.data.total || 0);
            if (statusFilter === 'created') {
                for (let i = 0; response.data.records.length > i; i++) {
                    yield fetchWallets(response.data.records[i].currency_code).then((wallet) => {
                        if (wallet && wallet.balance) {
                            response.data.records[i].poolBalance = wallet.balance.available;
                        }
                    });
                }
                return response.data.records;
            }
            else {
                return response.data.records;
            }
        }))
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    });
    const executeSettlement = (id) => {
        return axios
            .post(`/settlements/accounts/${id}/execute/`, null, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_EXECUTE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const declineSettlement = (id) => {
        return axios
            .post(`/settlements/accounts/${id}/decline/`, null, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_DECLINE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const manualExecute = (params) => {
        return axios
            .post(`/settlements/accounts/${params.settlementCode}/command/${params.commandCode}/manually_execute`, { reference_id: params.reference }, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_DECLINE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const fetchWallets = (currency_code) => __awaiter(void 0, void 0, void 0, function* () {
        const internalCode = ASSETS[currency_code.toUpperCase()];
        // Assets that are not in DLT custody
        if (['dash', 'eos', 'hbar', 'luna2', 'sgb', 'zec'].includes(currency_code)) {
            return undefined;
        }
        return yield axios
            .get(`/custody/${props.clientId}/wallets?limit=1000&state=all&currency_code=${internalCode}`)
            .then((res) => {
            const result = res.data.records.find((d) => d.label.toUpperCase().includes('POOL'));
            return result;
        });
    });
    const declineSettlements = (arr) => __awaiter(void 0, void 0, void 0, function* () {
        const ids = arr.map((item) => item.settlementCode);
        if (ids.length) {
            let n = 0;
            while (n < arr.length) {
                const id = ids[n];
                yield declineSettlement(id);
                n += 1;
            }
        }
    });
    const onManualExecute = (params) => __awaiter(void 0, void 0, void 0, function* () {
        yield manualExecute(params).finally(() => {
            getData();
        });
        showNotification({
            message: `Settlement was executed`,
            color: 'success',
            dispatch: errorNotice,
        });
    });
    const onExecuteSettlement = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield executeSettlement(id).finally(() => {
            getData();
        });
        showNotification({
            message: `Settlement was executed`,
            color: 'success',
            dispatch: errorNotice,
        });
    });
    const onDeclineSettlement = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield declineSettlement(id).finally(() => {
            getData();
        });
        showNotification({
            message: `Settlement was declined`,
            color: 'success',
            dispatch: errorNotice,
        });
    });
    const onDeclineAll = (settlements) => __awaiter(void 0, void 0, void 0, function* () {
        if (settlements) {
            yield declineSettlements(settlements).finally(() => {
                getData();
            });
            showNotification({
                message: `All settlements were declined`,
                color: 'success',
                dispatch: errorNotice,
            });
        }
    });
    const getData = () => __awaiter(void 0, void 0, void 0, function* () {
        let settlements = [];
        setLoading(true);
        yield fetchData().then((res) => {
            if (res) {
                settlements = res;
            }
        });
        setSettlements(mapSettlements(settlements));
        setLoading(false);
        return { settlements };
    });
    useEffect(() => {
        if (props.customersLoaded && !props.clientsLoading) {
            getData();
        }
    }, [statusFilter, state.page, state.rowsPerPage, props.selectedClient, props.customersLoaded]);
    useEffect(() => {
        if (props.customersLoaded &&
            !props.clientsLoading &&
            statusFilter !== 'created' &&
            state.orderBy === 'created_at') {
            getData();
        }
    }, [state.order, state.orderBy]);
    const handleChangePage = (event, newPage) => {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { page: newPage })));
    };
    const handleChangeRowsPerPage = (event) => {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { page: 0, rowsPerPage: parseInt(event.target.value, 10) })));
    };
    const onFilterChange = (filter) => {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { page: 0, order: 'desc' })));
        setStatusFilter(filter);
    };
    const handleToggleDialog = (result) => {
        setInitiateDialog(false);
        if (result) {
            getData();
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { page: 0 })));
            setStatusFilter('created');
        }
    };
    const handleDeclineAll = () => {
        setDeclineDialog(false);
        onDeclineAll(settlements);
    };
    const handleSort = (order, orderBy) => {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { order,
            orderBy })));
    };
    const visibleRows = React.useMemo(() => {
        return stableSort(settlements, getComparator(state.order, state.orderBy));
    }, [settlements, state.order, state.orderBy]);
    return (React.createElement(Container, { maxWidth: 'xl' },
        React.createElement(Box, null,
            React.createElement(Box, { height: '5px' }, loading && React.createElement(LinearProgress, null)),
            React.createElement(Box, { mb: 2, sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: `2px solid ${theme.palette.background.default}`,
                } },
                React.createElement(Tabs, { value: statusFilter, onChange: (e, value) => onFilterChange(value), "aria-label": 'basic tabs example' },
                    React.createElement(Tab, { label: 'Created', value: 'created', disabled: loading }),
                    React.createElement(Tab, { label: 'Pending', value: 'pending', disabled: loading }),
                    React.createElement(Tab, { label: 'Failed', value: 'failed', disabled: loading }),
                    React.createElement(Tab, { label: 'Declined', value: 'declined', disabled: loading }),
                    React.createElement(Tab, { label: 'Success', value: 'success', disabled: loading })),
                statusFilter !== 'created' ? null : (React.createElement(Box, { sx: { display: 'flex', gap: 1 } },
                    React.createElement(Button, { size: 'small', variant: 'contained', onClick: () => setInitiateDialog(true), disabled: settlements.length > 0 }, "Initiate"),
                    React.createElement(Button, { size: 'small', variant: 'contained', color: 'error', onClick: () => setDeclineDialog(true), disabled: !settlements.length }, "Decline all"))),
                React.createElement(Dialog, { open: initiateDialog, onClose: () => setInitiateDialog(false), fullWidth: true },
                    React.createElement(InitiateSettlements, { toggleDialog: (e) => handleToggleDialog(e), withDates: true })),
                React.createElement(Dialog, { open: declineDialog, onClose: () => setDeclineDialog(false), fullWidth: true },
                    React.createElement(DialogTitle, null, "Decline All Settlements"),
                    React.createElement(DialogContent, null, "Are you sure to decline all settlements?"),
                    React.createElement(DialogActions, null,
                        React.createElement(Button, { color: 'error', variant: 'contained', onClick: handleDeclineAll }, "Decline"),
                        React.createElement(Button, { onClick: () => setDeclineDialog(false), color: 'inherit' }, "Cancel"))))),
        loading ? null : (React.createElement(Box, null,
            React.createElement(React.Fragment, null, settlements.length ? (React.createElement(Box, { sx: { overflow: 'auto' } },
                React.createElement(ClientSettlementsTableBody, { rows: visibleRows, type: statusFilter, onRequestSort: handleSort, order: state.order, orderBy: state.orderBy, manualExecute: onManualExecute, executeSettlement: onExecuteSettlement, declineSettlement: onDeclineSettlement }))) : (React.createElement(Alert, { severity: 'info' }, "No settlements available"))),
            statusFilter !== 'created' && (React.createElement(TablePagination, { rowsPerPageOptions: [5, 10, 25, 1000], component: 'div', count: totalItems, rowsPerPage: state.rowsPerPage === 'all' ? totalItems : state.rowsPerPage, page: state.page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage }))))));
};
const stateToProps = (state) => {
    var _a;
    const { selectedClient, allClients, loading } = state.clientSearch;
    const { customers, loaded } = state.customersSearch;
    const clientId = (_a = state.client.clientInfo) === null || _a === void 0 ? void 0 : _a.code;
    return {
        selectedClient,
        clientId,
        customers,
        customersLoaded: loaded,
        clients: allClients,
        clientsLoading: loading,
    };
};
export default connect(stateToProps)(ClientSettlementsTable);
